module.exports = {
    // Initialise google analytics
    init: () => {
        /* global dataLayer */
        window.dataLayer = window.dataLayer || [];
        function gtag () {
            dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "UA-153530807-1");
    },
};
