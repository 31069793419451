module.exports = {
    // remove from the DOM the alert list item when user
    // interacts with the corresponding dismiss button
    init: () => {
        // return all alert list items
        const alertItemsClass = ".alerts__item";
        const alertItems      = document.querySelectorAll(alertItemsClass);
        // for each alert list item, find it's dismiss button
        alertItems.forEach(alertItem => {
            const dismissButtonClass = ".alerts__dismiss_button";
            const dismissButton      = alertItem.querySelector(dismissButtonClass);
            // listen for click of dismiss button
            dismissButton.addEventListener("click", event => {
                // remove corresponding list item from the DOM
                alertItem.parentNode.removeChild(alertItem);
                // check if alert list has any other children
                const alertListClass = ".alerts__list";
                const alertList      = document.querySelector(alertListClass);
                // if alert list is empty, remove it from the DOM
                if (alertList.childElementCount === 0) {
                    alertList.parentNode.removeChild(alertList);
                }
            });
        });
    },
};
