module.exports = {
    // display the 'proceed to purchase' button on terms & conditions view
    // as disabled unless agreement checkbox is checked
    init: () => {
        // get the 'form submission button'
        const proceedButtonID = "termsRequiredProceedButton";
        const proceedButton   = document.getElementById(proceedButtonID);
        if (proceedButton) {
            // set the button classes for the different display styles of the
            // 'proceed to purchase' button
            const buttonIsEnabledClass  = "button--primary_action";
            const buttonIsDisabledClass = "button--disabled";
            // get the terms & conditions checkbox
            const checkBoxID = "license_agree";
            const checkBox   = document.getElementById(checkBoxID);
            if (checkBox) {
                // listen to check whether the user has checked the checkbox
                checkBox.addEventListener("change", function (event) {
                    // on change, see whether the checkbox is checked
                    if (checkBox.checked === true) {
                        // if the checkbox is checked, change the 'proceed to purchase'
                        // button's class to show the user they can interact with it
                        if (proceedButton.classList.contains(buttonIsDisabledClass)) {
                            proceedButton.classList.replace(buttonIsDisabledClass, buttonIsEnabledClass);
                        }
                    } else {
                        // if the checkbox has been unchecked, change the 'proceed to purchase'
                        // button's class to show the user they cannot interact with it
                        if (proceedButton.classList.contains(buttonIsEnabledClass)) {
                            proceedButton.classList.replace(buttonIsEnabledClass, buttonIsDisabledClass);
                        }
                    }
                });
            }
        }
    },
};
