module.exports = {
    // display and hide the global navigation on smaller viewports
    init: () => {
        const globalNavigationButtonClass = ".global_navigation__button";
        const globalNavigationButton      = document.querySelector(globalNavigationButtonClass);
        const globalNavigationListClass   = ".global_navigation__list";
        const globalNavigationList        = document.querySelector(globalNavigationListClass);
        globalNavigationButton.onclick = () => {
            if (!globalNavigationList.hasAttribute("open")) {
                globalNavigationList.setAttribute("open", "");
            } else {
                globalNavigationList.removeAttribute("open");
            }
            if (!globalNavigationButton.hasAttribute("open")) {
                globalNavigationButton.setAttribute("open", "");
            } else {
                globalNavigationButton.removeAttribute("open");
            }
        };
    },
};
