"use strict";

// polyfill to enable us to use forEach on node lists in internet explorer
const ForEachPolyfill = require("./modules/foreach-polyfill");
ForEachPolyfill.init();

// polyfill to allow use of replace method on a classList in internet explorer
const ClassListPolyfill = require("./modules/classlist-polyfill");
ClassListPolyfill.init();

// allow users of internet explorer 11 & edge to interact with the details element
const DetailsPolyfill = require("./modules/details-polyfill");
DetailsPolyfill.init();

// interpolate between minimum ratio & maximum ratio, based on current viewport width
const TypeRatioInterpolation = require("./modules/type-ratio-interpolation");
TypeRatioInterpolation.init();

// detect focus source using a11y.js
const FocusAccessibility = require("./modules/focus-accessibility");
FocusAccessibility.init();

// close the alert bar
const AlertDismiss = require("./modules/alert-dismiss");
AlertDismiss.init();

// display and hide the global navigation on smaller viewports
const GlobalNavigation = require("./modules/global-navigation");
GlobalNavigation.init();

// display the 'proceed to purchase' button on terms & conditions view
// as disabled unless agreement checkbox is checked
const DisablePurchaseButtonForTerms = require("./modules/disable-purchase-button-for-terms");
DisablePurchaseButtonForTerms.init();

// Initialise google analytics
const GoogleAnalytics = require("./modules/google-analytics");
GoogleAnalytics.init();
