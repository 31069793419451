module.exports = {
    // allow users of internet explorer 11 & edge to interact with the details element
    init: () => {
        // check whether the user's browser is internet explorer 11 or edge
        if (document.documentMode || /Edge\//.test(navigator.userAgent)) {
            // set a variable with the tagName of the summary element
            const summaryTagName = "SUMMARY";
            // get all details elements in the DOM
            const detailsElements = document.querySelectorAll("details");
            // check whether there are any details elements in the DOM
            if (detailsElements) {
                // loop over details elements
                detailsElements.forEach(detailsElement => {
                    // store the current details element's direct children
                    const directChildrenOfDetails = detailsElement.children;
                    // loop over the current details element's direct children
                    directChildrenOfDetails.forEach(directChildOfDetails => {
                        // check whether this element is a summary element
                        if (directChildOfDetails.tagName !== summaryTagName) {
                            // if the element is not a summary element, hide it
                            directChildOfDetails.style.display = "none";
                        }
                    });
                    // listen for user interaction with this details element's summary element
                    const summaryElement = detailsElement.querySelector(summaryTagName);
                    summaryElement.onclick = () => {
                        directChildrenOfDetails.forEach(directChildOfDetails => {
                            // check whether this element is a summary element
                            if (directChildOfDetails.tagName !== "SUMMARY") {
                                // if the element is not a summary element, check if it has a display property
                                let directChildOfDetailsStyle = directChildOfDetails.getAttribute("style");
                                if (directChildOfDetailsStyle === "display: none;") {
                                    // if it does, remove the display property that we've added, to show the element
                                    directChildOfDetails.style.removeProperty("display");
                                    // add the open attribute to the parent details element,
                                    // to allow styles to apply to the triangle SVG
                                    detailsElement.setAttribute("open", "");
                                } else {
                                    // if it doesn't, give it a display of none, to hide the element
                                    directChildOfDetails.style.display = "none";
                                    // remove the open attribute to the parent details element,
                                    // to allow styles to apply to the triangle SVG
                                    detailsElement.removeAttribute("open");
                                };
                            }
                        });
                    };
                });
            };
        };
    },
};
